
 
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

@font-face {
  font-family: "bahnschrift"; 
  src: local("bahnschrift"),
    url("../src/fonts/bahnschrift.ttf") format("truetype");
}

:root {
  
  --color-primario        : #252e65;
  --color-complementario  : #094665;
  --color-gris-100        : #373737;
  --color-gris-75         : #666666;
  --color-gris-50         : #999999;
  --color-gris-25         : #cccccc;
  --color-gris-15         : #dcdcdc;
  --color-background      : #f9f9f9;
  --color-shadow          : rgba(0,0,0, 1 );
  --tipo-principal        : 'OpenSans-Regular';
  --tipo-secundaria       : 'Verdana';

  font-size               : 16px;

  text-decoration: none;

}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {text-decoration: none!important; cursor: pointer!important; }

.aLink {
  color: var(--color-gris-100);
}

.aLink:hover {
  color: var(--color-primario);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-brand {
  margin-right: 13rem;
  transition: .2s;
}

.bg-dark {
  background-color: rgba(0,0,0,0.01)!important;
 
  transition: .2s;
}

.bg-texture {
  background-image: url('../public/images/trama-background.jpg');
  background-repeat: none;
  background-size: contain;
}

.navbar-dark .navbar-nav .nav-link {
  color: var(--color-background);
  font-family: Roboto, sans-serif;
  font-size: .9rem;
  text-transform: uppercase;
  margin: 7px 10px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.25s;
  border-bottom: 2px solid rgba(0,0,0,0);
}
.nav-link--pipe {
  color: var(--color-background);
  padding-top: 10px;
}
.nav-link--wapp {
  color: var(--color-background);
  font-size: .9rem;
  text-transform: uppercase;
  margin-top: 14px;
  margin-left:  20px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.25s;
  border-bottom: 2px solid rgba(0,0,0,0);
}
.nav-link--wapp:hover {
  color: var(--color-color-gris-75 );
}
.navbar-dark .navbar-nav .nav-link:hover {
  border-bottom: 2px solid var(--color-background); 
}

.navbar-expand-lg .navbar-nav {
  display: flex;
}

.navbar-collapse {
  flex-grow: 0;
}
.justify-content-end {
  display: flex;
  justify-content: flex-end!important;
}


.container__top {
  margin-top: -115px!important;
}

/* NAVBAR SCROLLING ADJUSTMENTS  */
.navbar__scrolling {
  background-color: var(--color-primario)!important;
  box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.4);
}

.navbar-brand__scrolling {
  margin-right: 10rem;
}



/* CARROUSEL */
.carousel-caption {

  text-align: left;
  position: absolute;
  top: 68%;
  left: 40%;
  height: 30%;
  width: 40%;
  margin: -15% 0 0 -25%;

}

.carousel__titulo {
  font-family: 'bahnschrift', sans-serif;
  font-weight: 700;
  color: var(--color-background);
  font-size: 3.2rem;
}
.carousel__texto { 
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: var(--color-background);
  font-size: 1.1rem;
  line-height: 2.2rem;
  margin-bottom: 40px;
}

.carousel-indicators [data-bs-target] {

  width: 20px!important;
  height: 20px!important;
  border: none!important;
  border-radius: 10px!important;
  margin-bottom: 1rem;

}

.button-rounded {
  font-family: 'Roboto', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  border-radius: 25px!important;
  background-color: var(--color-primario);
  padding: 13px 40px;
  font-size: .8rem;
  text-transform: uppercase;
  border: none;

  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;

}

.button-rounded:hover {
  background-color: var(--color-background);
  color: var(--color-primario);
}

.button-rounded--outline {
  font-family: 'Roboto', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  border-radius: 25px!important;
  background-color: var(--color-background);
  color: var(--color-primario);
  padding: 13px 40px;
  font-size: .8rem;
  text-transform: uppercase;
  border: none;

  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;

}

.button-rounded--outline:hover {
  background-color: var(--color-primario);
  color: var(--color-background);
}

.cards__container {

 padding-bottom: 80px;

}

.cards__container--row>* {
  padding-right: 0;
}

.cards__body { 
  padding: 3rem 2rem 2.5rem 2rem!important; 
  
}

.card__container {
  border: none!important;
  box-shadow: 0px 0px 14px rgba(0,0,0,0.25)!important;
  width: 95%;
  margin-bottom: 2rem;
}

/* .section__container {
  padding: 2rem 3rem 6rem 3rem;
} */

.section__container {
  padding: 5rem 2.5rem 0rem 2.5rem;
  margin-bottom: 5rem;
}

.section__text {
  padding-top: 5rem;
}


.section__container--col {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.section__container--banner {
  
  background-color: var(--color-background);
  padding-top: 4rem;
  padding-bottom: 5rem;

}

.section__title {
  text-align: center;
  padding-top: 5rem;
  padding-bottom: 2rem;
  font-family: 'bahnschrift', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--color-gris-100 );
  font-weight: bolder;
  font-size: 2.5rem;
}

.section__subtitle {
  text-align: center;
  padding-top: 0rem;
  padding-bottom: 4rem;
  font-size: 1.2rem;
  font-weight: lighter;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--color-gris-100 );
  width: 60%;
  margin: 0 auto;
}


.section__text {
  padding: 0rem 2rem;
  text-align: left;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--color-gris-100 );
  font-weight: lighter;
  font-size: 1rem;
  line-height: 1.8rem;
}

.section__text--show { display: block!important;}
.section__text--hide { display: none!important;}

.cards__title {

  font-family: 'bahnschrift', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: bolder;
}

.cards__text {

  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: lighter;
  margin-bottom: 2rem;
}

.banner__row  { --bs-gutter-x : 0px; }

.banner__container {
  margin: 0px; 
  padding: 20px 0px;
  background-color: var(--color-complementario);
  background-image: url('../public/images/banner-back.jpg');
  background-repeat: no-repeat;
  background-position: right top;

}

.banner__container--gutter {
  --bs-gutter-x: 0rem;
}
.banner__container--col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner__title {

  font-family: 'bahnschrift', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  font-weight: bolder;
  color: var(--color-background);
  margin-bottom: 1rem;

}

.banner__text {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  line-height: 1.8rem;
  font-weight: lighter;
  margin-bottom: 2rem;
  color: var(--color-background);
}

.banner__container--button { 
  margin: left;
}

.preg__desktop {display: block;}
/* .show {display: none;} */

.preg__text {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  font-weight: lighter;
  margin-bottom: 1rem;
  color: var(--color-gris-100);
  padding: .5rem 3rem .5rem .5rem;

}

.preg__text--select {
  animation: fadeIn .5s;
  -webkit-animation: fadeIn .5s;
  -moz-animation: fadeIn .5s;
  -o-animation: fadeIn .5s;
  -ms-animation: fadeIn .5s;
  background-color: rgba(0, 0, 0, 0.05);
}

.accordion-button:not(.collapsed) {
  color: var(--color-gris-100);
  background-color: var(--color-gris-15);
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.accordion-body {
  background-color: rgba(0,0,0,.1)!important;
}


.footer__container {
  background-color: var(--color-primario);
  color: var(--color-background);
  padding-top: 6rem;
  padding-bottom: 3rem;
}

.footer__textBlock {
  width: 94%;
  margin: 0 auto;
  padding-bottom: .8rem;
}
.footer__icon{
  text-align: center;
  min-width: 20px;
}

.footer__title {
  padding-bottom: 0.2rem;
  font-family: 'bahnschrift', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--color-background );
  font-weight: bolder;
  font-size: 1rem;
}

.footer__text {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: .8rem;
  font-weight: lighter;
  margin-bottom: .4rem;
  color: var(--color-background);
  /* padding-right: 3rem; */
  padding-left: 1rem;
 

}

.footer__link {

  padding-right: 1rem;
  padding-left: 0rem;
  padding-bottom: .5rem;
  border-bottom: 1px solid var(--color-primario);
  
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.25s;

  width: fit-content;
}

.footer__link:hover {
  border-bottom: 1px solid var(--color-background);
}

.footer__textBlock{
  display: flex;
}

.footer__email {
  color: var(--color-background);
}

.footer__email:hover {
  color: var(--color-gris-25);
}
.header__container {
  margin-top: -100px;
  padding: 0px;
  position: relative;
}

.header__image {
  z-index: 100;
  width: 100vw;
  min-height: 380px;
  object-fit: cover;
  object-position: top;
}

.header__text {
  position: absolute;
  top: 50%;
  left: 100px;
  z-index: 150;
}

.header__text--title {
  font-family: 'bahnschrift', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--color-background );
  font-weight: bolder;
  font-size: 4rem;
}



.qsomos__img {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.accordion-body {
  display: flex;
  align-content: center;
  align-items: center;
}

.qsomos__img img{
  width: 70%;
  margin: 0 auto;
  text-align: center;
}

.qsomos__text--bloque {

    padding: 0 2rem 0 0;
    text-align: left;
    font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--color-gris-100 );
    font-weight: lighter;
    font-size: 1.2rem;
    line-height: 2rem;
  
}

.qsomos__txt {
  
  font-family: 'bahnschrift', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--color-gris-100 );
  font-weight: lighter;
  font-size: 1.2rem;
  line-height: 2rem;
  text-align: center;
  margin-top: 2rem;

}


.qsomos__copy {
  
  font-family: 'bahnschrift', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--color-gris-100 );
  font-weight: lighter;
  font-size: .8rem;
  line-height: 2rem;
  text-align: left;
  margin-top: 1rem;
}


.bloque__img {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: 1rem;

}

.bloque__img--start {

  justify-content: start!important;

}

.bloque__img img{
  padding-top: 1rem;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.bloque__header {

}

.bloque__izq { padding: 0rem 2rem 2rem 1rem;}
.bloque__der { padding: 2rem 0rem 2rem 2rem;}

.bloque__titulo {
  font-family: 'bahnschrift', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--color-gris-100 );
  font-weight: bolder;
  font-size: 1.2rem;
  line-height: 2rem;
  text-align: left;
  margin-top: 3rem;
  margin-bottom: 0rem;
}
.bloque__titulo--center {
  text-align: center;
}
.bloque__text {
  font-family: 'Open', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--color-gris-100 );
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.7rem;
  text-align: left;
  margin-top: 1rem;
}

/******* ANIMACIONES *****/

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fast {
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}


.contact__container--grey {
  background-color: var(--color-background);
}
.contact__map {
  background-color: var(--color-primario);
}
.contact__container {
  background-color: var(--color-primario);
}
.contact__text {
  padding-top: 3rem;
  padding-bottom: 0px;
  color: var(--color-background);
  text-align: center;
}

.preg__desktop {display: block;}
.preg__mobile {display: none;}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}



/* BREACKPOINTS  */

/* // X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) { }

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .carousel-caption {
    top: 64%!important;
    left: 40%;
    height: 30%;
    width: 50%;
  }
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 

  .bg-dark {
    background-color: var(--color-primario)!important;
    border-bottom: 1px solid rgba( 255, 255, 255, 0.7)!important;
  }


  .carousel {
    min-height: 50vh;
  }

  .carousel__container--images {
    height: 70vh!important;
    width: auto!important;
    object-position: center;
  }

  .carousel-caption {

    text-align: left;
    position: absolute;
    top: 60%;
    left: 40%;
    height: 30%;
    width: 70%;
    margin: -15% 0 0 -25%;
  
  }

  .preg__desktop {display: none;}
  .preg__mobile {display: block;}

  .header__container {
    margin-top: -10px;
  }
  
  .header__text {
    left: 35px;
    top: 35%;
  }
  .header__image {
    min-height: 480px;
    object-position: 0px;
  }
  
  .section__container-- {
    display: flex;
  }

  .qsomos__text--bloque {

    margin: 4rem 0rem;
    text-align: center;
  
  }
  
  .qsomos__txt {

    margin-bottom: 3rem;
  
  }

  .footer__linkBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer__textBlock {
    justify-content: center;
  }
  .footer__logo {
    height: 200px!important;
    
  }
  .footer__logo--position {
    text-align: center;
    margin-bottom: 3rem;
  }
  .footer__link {
    padding-right: 0rem;
  }
  .footer__title, .footer__text, .footer__link {
    text-align: center!important;
  }

  .footer__icon {
    margin-bottom: 1rem
  }
  .footer__textBlock {
    flex-direction: column;
  }

}


.carousel__box--image {
  object-fit: cover;
  max-height: 700px!important;
  width: 100%;
  margin: 0 auto;
}


/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 

  .bg-dark {
    background-color: var(--color-primario)!important;
    border-bottom: 1px solid rgba( 255, 255, 255, 0.7)!important;
  }

  .container__top {
    margin-top: -1px!important;
  }

  .carousel {
    min-height: 50vh;
  }

  .carousel__container--images {
    height: 70vh!important;
    width: auto!important;
    object-position: center;
  }

  .carousel-caption {
    text-align: center;
    width: 70vw;
    top: 50%!important;
  }
  
  .carousel__titulo {
    font-family: 'bahnschrift', sans-serif;
    font-weight: 600;
    color: var(--color-background);
    font-size: 2.8rem;
    margin-bottom: 2.5rem;
  }

  .carousel__texto { 
   display: none;
  }

  .header__text--title {
    font-size: 3rem;
    text-align: center;
  }
  
  .banner__container {
    background-image: none; 
    width: 100vw; 
    padding-bottom: 6rem;
    padding-top: 2rem
  }

  .banner__container--drone {
    padding: 3rem;
    width: 100vw;
    text-align: center;
  }

  .banner__container--drone img {
    height: 200px;
    margin: 0 auto;
  }

  .banner__title {
    text-align: center;
  }

  .banner__text {
    text-align: center;
    padding: 0 3rem;
  }

  .banner__container--button { 
    margin: 0 auto;
  }

  .section__container {
    padding: 2rem 1.5rem 4rem 1.5rem;
  }

  
  .section__subtitle {
   
    width: 100%;
    margin: 0 auto;
  }

  

 }


/* // X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
  .carousel-caption {
    text-align: center;
    top: 45%!important;
  }
}


/* ANIMATIONS  */
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

/* div {border: 1px solid red} */