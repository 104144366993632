
.gallery__cont {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 20px;
  }
  
  .column {
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
  }
  
  .column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
    filter:  brightness(1);
    transition: all 0.3s ease;
  }
  
  .column img:hover {
    cursor: pointer;
    filter:  brightness(1.5);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    transform: scale(1.05);
  }
  

  .modal-content {
    transform: 0.3s;
  }

  .modal__container {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0%;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10050;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease-in;
  }
  
  .modal__lapituquita {
    background-color: rgba(255, 255, 255, 0.9);
    width: 80%;
    height: 80%;
    border-radius: 5px;
    text-align: center;
    padding: 20px;
    position: relative;
    display: flex;
    justify-content: center;
  }
  
  .modal__lapituquita img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  
  .modal__active {
    visibility: visible;
    opacity: 1;
  }
  
  .modal__close {
    position: absolute;
    top: -17px;
    right: -17px;
    background-color: white;
    width: 37px;
    height: 37px;
    border-radius: 55%;
    padding-top: 2px;
    transition: all 0.5 ease;
  }
  
  .modal__close:hover {
    background-color: black;
    color: #FFF;
    cursor: pointer;
  }
  
  .modal__button {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    cursor: pointer;
  }
  
  .modal__button:hover {
    color: #f5a475 !important;
  }

  .modal__size {
      height: auto;
      max-width: 100%;
      margin: 0 auto;
  }
  .modal__desc {
    justify-content: center;
  }

@media (max-width: 1024px) {.column {
    flex: 50%;
    max-width: 50%;
   
} }

@media (max-width: 768px) {
   
}

@media (max-width : 576px) {
   
}

/* 

// SIZE XXL
// @media (max-width: 1400px) {}
// // SIZE XL
// @media (max-width: 1200px) {}
// // SIZE L
// @media (max-width: 1024px) {}
// // SIZE MD
// @media (max-width: 768px) {}
// // SIZE XS
// @media (max-width : 576px) {}

 */
