.float {
    position: fixed;
    width: 60px;
    height: 60px;
    /* top: 87vh;
    left: 80vw; */
    bottom: 30px;
    right: 50px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.grecaptcha-badge {
    visibility: hidden;
}


/* .fa-whatsapp {
    color: #FFF;
}

.fa-whatsapp:hover {
    color: #25d366;
} */

.float:hover {
    text-decoration: none;
    color: #25d366;
    background-color: #fff;
}

.my-float {
    margin-top: 15px;
    margin-left: 2px;
    color: #FFF;
}

.my-float:hover {
    margin-top: 15px;
    margin-left: 2px;
    color: #25d366;
}



@media (max-width: 767.98px) { 
.float {
   
    top: 70vh;
    left: 70vw;
   
}}